@use "src/stylesheets/variables/spacings";
@use "src/stylesheets/variables/typo";

.timelineSteps {
  margin-top: spacings.$s-7;
  margin-bottom: spacings.$s-18;
}

:global(.ant-steps-item-title) {
  font-size: typo.$m;
}

:global(.ant-steps-item-description) {
  font-size: typo.$s;
  margin-top: spacings.$s-2;
}

:global(.ant-steps-item-tail) {
  top: spacings.$s-4;
}
:global(.ant-steps-label-vertical .ant-steps-item-tail) {
  margin-left: 3.5rem;
  padding: spacings.$s-1 spacings.$s-7;
}

.timelineSteps :global {
  .ant-steps-item-wait > .ant-steps-item-container {
    .ant-steps-item-icon {
      border: 2px solid hsl(var(--english-breakfast-400));
    }
    svg {
      [class$="cls-1"],
      [class$="cls-2"] {
        stroke: hsl(var(--english-breakfast-500)) !important;
      }
      [class$="cls-3"] {
        fill: hsl(var(--english-breakfast-500)) !important;
      }
    }
    .ant-steps-item-title {
      color: hsl(var(--english-breakfast-500));
    }
  }

  .ant-steps-item-finish > .ant-steps-item-container {
    .ant-steps-item-icon {
      border: 2px solid hsl(var(--english-breakfast-400));
    }
    .ant-steps-item-title {
      color: hsl(var(--english-breakfast-500));
    }
  }

  .ant-steps-item-process > .ant-steps-item-container {
    .ant-steps-item-icon {
      background: hsl(var(--english-breakfast-800));
      border-color: hsl(var(--english-breakfast-800));
      .ant-steps-icon {
        svg {
          [class$="cls-1"],
          [class$="cls-2"] {
            stroke: hsl(var(--starbright-600)) !important;
          }
          stroke: hsl(var(--starbright-600)) !important;
        }
      }
    }

    .ant-steps-item-tail:after {
      background-color: hsl(var(--english-breakfast-500));
    }

    .ant-steps-item-title {
      color: hsl(var(--english-breakfast-800));
    }
  }

  .ant-steps:not(.ant-steps-vertical) .ant-steps-item-custom .ant-steps-item-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: spacings.$s-10;
    height: spacings.$s-10;

    .ant-steps-icon {
      width: spacings.$s-6;
      height: spacings.$s-6;
    }
  }

  .ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: spacings.$s-10;
    height: spacings.$s-10;
    border: 1px solid hsl(var(--english-breakfast-800));
  }

  .ant-steps-label-vertical .ant-steps-item-icon {
    margin-left: 2rem;
  }

  .ant-steps-item-wait > .ant-steps-item-container {
    .ant-steps-item-tail:after {
      background-color: hsl(var(--english-breakfast-500));
    }
  }

  .ant-steps-item-finish > .ant-steps-item-container {
    .ant-steps-item-tail:after {
      background-color: hsl(var(--english-breakfast-500));
    }
  }

  .ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
    left: spacings.$s-5;
    top: spacings.$s-4;
    padding: 1.65rem 0 1px;
  }
  .ant-steps-vertical > .ant-steps-item {
    margin-bottom: spacings.$s-4;
  }
}
