$spacing: 0.25rem;

$s-1: 1 * $spacing;
$s-2: 2 * $spacing;
$s-3: 3 * $spacing;
$s-4: 4 * $spacing;
$s-5: 5 * $spacing;
$s-6: 6 * $spacing;
$s-7: 7 * $spacing;
$s-8: 8 * $spacing;
$s-9: 9 * $spacing;
$s-10: 10 * $spacing;
$s-11: 11 * $spacing;
$s-12: 12 * $spacing;
$s-13: 13 * $spacing;
$s-14: 14 * $spacing;
$s-15: 15 * $spacing;
$s-16: 16 * $spacing;
$s-17: 17 * $spacing;
$s-18: 18 * $spacing;
$s-19: 19 * $spacing;
$s-20: 20 * $spacing;

