@use "src/stylesheets/variables/typo";
@use "src/stylesheets/variables/spacings";

.modal {

  :global(.ant-modal-content) {
    color: hsl(var(--dark-orchestra-600));
    border-radius: 20px;

    background: hsl(var(--background));
  }

  :global(.ant-modal-body) {
    padding: spacings.$s-7;
  }

  :global(.ant-modal-mask) {
    background-color: hsl(var(--english-breakfast-900), 0.8);
  }

  :global(.anticon) svg {
    width: spacings.$s-6;
    height: spacings.$s-6;
    margin-top: spacings.$s-4;
  }
}

