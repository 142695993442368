@use "src/stylesheets/variables/typo";
@use "src/stylesheets/variables/spacings";

.pill {
  border-radius: 4px;
  font-size: typo.$xs;
  padding: 0.25rem 0.5rem;
  white-space: pre;

  &:global(.small) {
    font-size: typo.$xxs;
  }

  &:global(.live) {
    color: hsl(var(--starbright-600));
    background-color: hsl(var(--army-green-600));
    border-color: hsl(var(--army-green-600));
  }

  &:global(.debunked) {
    color: hsl(var(--starbright-600));
    background-color: hsl(var(--red-reign-600));
    border-color: hsl(var(--red-reign-600));
  }

  &:global(.timelockstarted) {
    color: hsl(var(--starbright-600));
    background-color: hsl(var(--gryphonne-sepia-wash-600));
    border-color: hsl(var(--gryphonne-sepia-wash-600));
  }

  &:global(.withdrawn) {
    color: hsl(var(--starbright-600));
    background-color: hsl(var(--schiava-blue-600));
    border-color: hsl(var(--schiava-blue-600));
  }

  &:global(.challenged) {
    color: hsl(var(--starbright-600));
    background-color: hsl(var(--gryphonne-sepia-wash-600));
    border-color: hsl(var(--gryphonne-sepia-wash-600));
  }
}
