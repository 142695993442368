@use 'src/stylesheets/variables/typo';

@function shadowTemplate($color) {
  @return 0rem 0.25rem 0.5rem 0rem hsl($color, 0.25),
  inset 0.125rem 0.125rem 0.5rem 0rem hsl($color, 0.50);
}

.button {
  height: auto !important;
  border-radius: 4px;
  min-width: 10.5rem;
  border-width: 1px;
  padding: 0.75rem 1.5rem;
  font-size: typo.$s;
  outline: none;

  background-color: hsl(var(--english-breakfast-600));
  border-color: hsl(var(--english-breakfast-600));
  color: hsl(var(--starbright-600));
  box-shadow: shadowTemplate(var(--english-breakfast-700));

  &:global(.small) {
    padding: 0.5rem 0.75rem;
    font-size: typo.$xs;
    min-width: unset;
  }

  &:global(.secondary) {
    background-color: transparent;
    border-color: hsl(var(--english-breakfast-800));
    color: hsl(var(--dark-orchestra-600));
    box-shadow: none;
  }

  &:hover, &:active, &:focus {
    background-color: hsl(var(--english-breakfast-700));
    border-color: hsl(var(--english-breakfast-700));
    color: hsl(var(--starbright-600));
    box-shadow: shadowTemplate(var(--english-breakfast-800));
  }

  &[disabled], &[disabled]:hover {
    background-color: hsl(var(--smooth-coffee-200));
    border-color: hsl(var(--smooth-coffee-200));
    color: hsl(var(--smooth-coffee-500));
    box-shadow: shadowTemplate(var(--smooth-coffee-300));
  }
}




