@use "src/stylesheets/variables/typo";

$minVW: 320px;
$maxVW: 1200px;
$minFS: 15px;
$maxFS: 18px;

@import url("https://fonts.googleapis.com/css2?family=Texturina:ital,opsz,wght@0,12..72,100;0,12..72,200;0,12..72,300;0,12..72,400;0,12..72,500;0,12..72,600;0,12..72,700;0,12..72,800;0,12..72,900;1,12..72,100;1,12..72,200;1,12..72,300;1,12..72,400;1,12..72,500;1,12..72,600;1,12..72,700;1,12..72,800;1,12..72,900&display=swap");

@font-face {
  font-family: "EdwardianScriptITC";
  src: url("../assets/fonts/EdwardianScriptITC.woff2") format("woff2");
}

html,
body {
  @include typo.fluid-type($minVW, $maxVW, $minFS, $maxFS);
}

/* *:not(input) {
  caret-color: transparent;
}
 */
.displayBlock {
  display: block;
}

@keyframes blinker {
  0% {
    opacity: 0;
  }

  60% {
    opacity: 100%;
  }
}

.blink {
  animation: blinker 2s running forwards;
}

hr {
  border: none;
  height: 1px;
  margin-block: 0;
}

//a:first-child:nth-last-child(1) {
//  text-decoration: underline;
//} This did not work as expected.

footer svg {
  height: 1rem;
}

* {
  font-family: "Texturina", serif;

  font-weight: normal;
  line-height: 1.5;
  border-width: 1px;
}

h1 {
  font-size: typo.$xxl;
  margin-block-start: 0.67rem;
}

h2 {
  font-size: typo.$xl;
  margin-block-start: 0.83rem;
}

h3 {
  font-size: typo.$l;
  margin-block-start: 1rem;
}

h4 {
  font-size: typo.$m;
}

p {
  font-size: typo.$s;
}
ul,
ol {
  margin-block-end: 0;
}
p:has(+ ul),
p:has(+ ol) {
  margin-block-end: 0;
  margin-bottom: 0;
}

li {
}

small {
  font-size: typo.$xs;
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  display: inline-block;

  &:hover {
    text-overflow: clip;
    white-space: normal;
    word-break: break-word;
  }
}

// TODO Refactor <a> and <button> styles which are duplicated.
@function shadowTemplate($color) {
  @return 0rem 0.25rem 0.5rem 0rem hsl($color, 0.25), inset 0.125rem 0.125rem 0.5rem 0rem hsl($color, 0.5);
}

a.button {
  height: auto !important;
  border-radius: 4px;
  min-width: 10.5rem;
  border-width: 1px;
  border-style: solid;
  padding: 0.75rem 1.5rem;
  font-size: typo.$s;
  outline: none;

  background-color: hsl(var(--english-breakfast-600));
  border-color: hsl(var(--english-breakfast-600));
  color: hsl(var(--starbright-600));
  box-shadow: shadowTemplate(var(--english-breakfast-700));

  &.small {
    padding: 0.5rem 0.75rem;
    font-size: typo.$xs;
    min-width: unset;
  }

  &.secondary {
    background-color: transparent;
    border-color: hsl(var(--english-breakfast-800));
    color: hsl(var(--dark-orchestra-600));
    box-shadow: none;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: hsl(var(--english-breakfast-700));
    border-color: hsl(var(--english-breakfast-700));
    color: hsl(var(--starbright-600));
    box-shadow: shadowTemplate(var(--english-breakfast-800));
  }

  &[disabled],
  &[disabled]:hover {
    background-color: hsl(var(--smooth-coffee-200));
    border-color: hsl(var(--smooth-coffee-200));
    color: hsl(var(--smooth-coffee-500));
    box-shadow: shadowTemplate(var(--smooth-coffee-300));
  }
}
