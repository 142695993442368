@use "src/stylesheets/variables/spacings";
@use "src/stylesheets/variables/breakpoints";

html {
  height: auto;
}

body {
  height: fit-content;
  min-height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
}

#app {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}

nav {
  margin-left: spacings.$s-8;
  margin-right: spacings.$s-8;

  & > *[hide] {
    display: none;
  }
}

header:not(article > header):not(section > header),
main,
footer {
  padding-left: spacings.$s-8;
  padding-right: spacings.$s-8;
}

@media (min-width: breakpoints.$small) {
  nav,
  main > section,
  main > article {
    padding-right: spacings.$s-7;
    padding-left: spacings.$s-7;
  }

  nav {
    &.homeNav {
      padding-right: spacings.$s-7;
      padding-left: spacings.$s-7;
    }
  }
}

header:not(article > header):not(section > header) {
  padding-top: spacings.$s-20;
  padding-bottom: spacings.$s-5;
}

header + nav:first-of-type {
  position: sticky;
  top: 0; // Go sticky when top distance is zero
}

main {
  padding-top: spacings.$s-10;
  padding-bottom: spacings.$s-18;

  &.zero-padding {
    padding-left: 0;
    padding-right: 0;
  }

  > article,
  > section {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;

    &.fullscreen {
      max-width: 100%;
    }
  }
}

footer {
  margin-top: auto;
}
