@use "src/stylesheets/variables/typo";
@use "src/stylesheets/variables/spacings";
@use "src/stylesheets/variables/breakpoints";

.notificationCenter {
  h4 {
    font-size: typo.$m;
  }
  .bell {
    margin-right: 20px;
    padding: 8px 14px;
    border: 1px solid black;

    &:hover {
      cursor: pointer;
    }
  }
}

:global(.ant-modal-footer) {
  display: flex;
  justify-content: space-between;
  padding: 20px 31.5px;
}

.notificationModal {
  width: 90%;

  @media (min-width: breakpoints.$small) {
    width: 70%;
  }

  @media (min-width: breakpoints.$large) {
    width: 35%;
  }

  .header {
    display: flex;
    justify-content: space-between;
    margin-top: spacings.$s-4;
  }

  .container {
    overflow: hidden;

    .content {
      height: 360px;
      padding: 10px 0;
      overflow-x: hidden;
      overflow-y: scroll;

      @media (min-width: breakpoints.$small) {
        height: 500px;
      }

      .emptyMessage {
        font-size: typo.$s;
      }

      .item {
        display: grid;
        grid-template-columns: 40px 1fr 40px;
        gap: 8px;
        width: 95%;
        padding: 0.8rem;
        margin-bottom: spacings.$s-4;
        background: rgba(0, 0, 0, 0.03);
        border-radius: 8px;

        a {
          text-decoration: underline;
        }

        .itemContent {
          font-size: typo.$s;
          margin-bottom: spacings.$s-2;
        }

        .mark {
          justify-self: flex-end;
          width: spacings.$s-2;
          height: spacings.$s-2;
          margin-top: spacings.$s-3;
          border-radius: 50%;
          background: brown;
          cursor: pointer;

          &.read {
            background: green;
          }
        }
      }
    }
  }
}
